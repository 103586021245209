<template>
  <div class="benefit-item">
    <div class="benefit-image">
      <img class="benefit-icon" :src="item.image" />
    </div>
    <span class="benefit-title">{{ item.title }}</span>
    <p class="benefit-description">
      {{ item.description }}
    </p>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
<style lang="scss" scoped>
.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212;
  padding: 0 15px 25px;

  @media screen and (max-width: 499px) {
    padding: 0 15px 25px;
  }

  .benefit-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 132px;
    width: 100%;

    .benefit-icon {
      display: block;
      // max-height: 95px;
      max-width: 100%;
      //   object-fit: contain;
      height: 132px;
    }
  }

  .benefit-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 139%;
    text-align: center;
    color: white;
    margin-top: 24px;
  }

  .benefit-description {
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 152.5%;
    color: white;
    text-align: center;
  }

  &--additional {
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: 499px) {
      padding: 0;
    }

    .benefit-title {
      margin-top: 5px;
    }

    .benefit-list {
      list-style: none;
      padding-left: 0;
      margin-top: 12px;

      .benefit-list-item {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 139%;
        text-transform: capitalize;

        & + .benefit-list-item {
          margin-top: 12px;
        }
      }
    }

    .show-more {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      margin-top: auto;

      &:active,
      &:visited {
        color: white;
        text-decoration: none;
      }

      @media screen and (max-width: 499px) {
        margin-top: 39px;
      }
    }
  }
}
</style>